//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskEntrust } from '@/api/modular/flowable/handleTaskManage'
import { sysUserSelector } from '@/api/modular/system/userManage'
export default {

  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      recordData: {},
      userSelector: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
      this.sysUserSelector()
    },
    sysUserSelector () {
      sysUserSelector().then((res) => {
        this.userSelector = res.data
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values.taskId = this.recordData.id
          handleTaskEntrust(values).then((res) => {
            if (res.success) {
              this.$message.success('委托成功')
              this.returnPage()
            } else {
              this.$message.error('委托失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.form.resetFields()
      this.visible = false
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
